import { Homepage, BlogContentPage, AboutPage, AdvertisePage, ContactPage, PrivacyPage, TermsPage, NotFound, Blogpage } from "./pages";
import { Routes, Route } from 'react-router-dom';
import useFetch from './hooks/useFetch';

function App() {
  let { loading, data, error } = useFetch('https://strapi-production-3c32.up.railway.app/api/blogs?populate=*')
  if (loading) return <p>Loading...</p>
  if (error) return <p>Error!</p>
  console.log(data)


  return (
    <div className="App">
      <Routes>
        <Route path='*' element={<NotFound />}></Route>
        <Route path='/' element={<Homepage blogs={data ? data : ""} />}></Route>
        <Route path='/blog/:id' element={<BlogContentPage blogs={data ? data : ""} />}></Route>
        <Route path='/blog' element={<Blogpage blogs={data ? data : ""} />}></Route>
        <Route path='/about' element={<AboutPage />}></Route>
        <Route path='/contact' element={<ContactPage />}></Route>
        <Route path='/advertise' element={<AdvertisePage />}></Route>
        <Route path='/privacy' element={<PrivacyPage />}></Route>
        <Route path='/terms' element={<TermsPage />}></Route>
      </Routes>
    </div >
  );
}

export default App;
