import React, { useState } from 'react';
import { menu, close, logo } from '../assets';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [toggle, setToggle] = useState(false);
    const handleClick = () => setToggle(!toggle)


    return (
        <div className='w-full h-[80px] z-10 text-white fixed drop-shadow-lg relative' style={{
            backgroundColor: "rgba(0, 0, 0)", letterSpacing: '0.01em'
        }}>
            <div className='flex justify-between items-center w-full h-full md:max-w-[1240px] m-auto'>

                <div className='flex items-center'>
                    <Link to={`/`}><img src={logo} alt="logo" style={{ maxWidth: '235px' }} className='ml-3 sm:ml-10 ss:ml-10 md:ml-3 opacity-[100%] w-full h-[35px]' /></Link>
                </div>


                <div className='flex items-center'>
                    <ul className='hidden md:flex'>
                        <Link to={`/`}><li>HOME</li></Link>
                        <Link to={`/about`}><li>ABOUT</li></Link>
                        <Link to={`/blog`}><li>BLOG</li></Link>
                        <Link to={`/advertise`}><li>ADVERTISE</li></Link>
                        <Link to={`http://www.henryapaw.com/calculator`} target='blank'><li>FREE READING</li></Link>
                        <Link to={`/contact`}><li>CONTACT US</li></Link>
                    </ul>
                </div>


                <div className='md:hidden' onClick={handleClick}>
                    <img src={!toggle ? menu : close} alt="menu" className='w-[28px] h-[28px] object-contain mr-10' />
                </div>



            </div>
            <ul className={toggle ? 'absolute w-full px-8 md:hidden' : 'hidden'} style={{ backgroundColor: "rgba(0, 0, 0)" }}>
                <Link to={`/`}><li>HOME</li></Link>
                <Link to={`/about`}><li>ABOUT</li></Link>
                <Link to={`/advertise`}><li>ADVERTISE</li></Link>
                <Link to={`http://www.henryapaw.com/calculator`}><li>FREE READING</li></Link>
                <Link to={`/contact`}><li>CONTACT US</li></Link>

            </ul>
        </div >




    )
}

export default Navbar