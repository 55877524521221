
import React from 'react';



const FeatBlog = () => {

    return (
        <div >
            <section>
                <div className="">

                    <ul className="grid grid-cols-1 lg:grid-cols-3">
                        <li className="lg:col-span-2 lg:col-start-1 lg:row-span-3 lg:row-start-1 p-2">
                            <a href="/blog/Rejuvenate-and-Renew:-Europe's-Premier-Wellness-Retreats-for-2024" className="relative block group bg-black">
                                <img
                                    src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1706054932/blog-cover-10_x5pn8d.jpg"
                                    alt=""
                                    className="object-cover w-full transition duration-500 aspect-square group-hover:opacity-30"
                                />

                                <div
                                    className="absolute inset-0 flex flex-col items-start justify-end p-6"
                                >
                                    <span className="text-sm  font-medium text-[#f2ca50]">LIFESTYLE</span>
                                    <h3 className="text-3xl font-medium text-white">Rejuvenate and Renew: Europe's Premier Wellness Retreats for 2024</h3>

                                    <p className="text-lg  text-white">In our fast-paced world, finding a sanctuary to reset your wellness compass is essential.Whether you crave a holistic mind-body detox, transformative treatments</p>
                                </div>
                            </a>
                        </li>

                        <li className="p-2">
                            <a href="/blog/Mastering-the-Art-of-Sleep:-A-Guide-to-Enhance-Your-Well-Being" className="relative block group bg-black">
                                <img
                                    src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1706049317/blog_cover_9_a288b40aa8_abrymi.jpg"
                                    alt=""
                                    className="object-cover w-full transition duration-500 aspect-square group-hover:opacity-30"
                                />

                                <div
                                    className="absolute inset-0 flex flex-col items-start justify-end p-6"
                                >
                                    <span className="text-sm  font-medium text-[#f2ca50]">WELLNESS</span>
                                    <h3 className="text-3xl font-medium text-white">Mastering the Art of Sleep: A Guide to Enhance Your Well-Being</h3>

                                    <p className="text-lg  text-white">In the hustle and bustle of modern life, sleep often takes a back seat, despite being a cornerstone of our physical and mental well-being.</p>
                                </div>
                            </a>
                        </li>
                        <li className='p-2'>
                            <a href="/blog/The-Power-of-Grounding:-Reconnecting-with-Nature-for-Optimal-Well-being" className="relative block group bg-black">
                                <img
                                    src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1706049319/blog_cover_8_7b11ca68ac_csjvza.jpg"
                                    alt=""
                                    className="object-cover w-full transition duration-500 aspect-square group-hover:opacity-30"
                                />

                                <div
                                    className="absolute inset-0 flex flex-col items-start justify-end p-6"
                                >
                                    <span className="text-sm  font-medium text-[#f2ca50]">WELLNESS</span>
                                    <h3 className="text-3xl font-medium text-white drop-shadow-md">The Power of Grounding: Reconnecting with Nature for Optimal Well-being</h3>

                                    <p className="text-lg  text-white">In our fast-paced, technology-driven world, it's easy to lose touch with the natural elements that surround us.</p>
                                </div>
                            </a>
                        </li>


                    </ul>
                </div>
            </section>
        </div>
    )
}
export default FeatBlog