
import { Navbar, FooterNew } from "../components";

export default function NotFound() {
    return (
        <div>
            <Navbar /><section class="bg-white dark:bg-gray-900">
                <div className="bg-white dark:bg-gray-900">
                    <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                        <div className="max-w-screen-md mb-8 lg:mb-16">
                            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">404 Error - Sorry we couldn't find the page</h2>
                            <p className="mb-4 text-gray-500 sm:text-xl dark:text-gray-400">Here are some helpful links above and below to get you back to where you want to be</p>

                        </div>
                    </div>
                </div></section>
            <FooterNew />
        </div>
    )
}