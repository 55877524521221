
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';



const Newsletter = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_z5u60o5', 'template_je3ils8', form.current, 'syC2Qp6eZUQUv8-Jr')
            .then((result) => {
                console.log(result.text);
                alert("SUCCESS! - your email has now been sent");
            }, (error) => {
                console.log(error.text);
            });
    };

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const emailValidation = (e) => {
        const regEx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (regEx.test(email)) {
            setMessage("Thank you for subscribing to our newsletter!")
        } else if (!regEx.test(email) && email !== '') {
            setMessage("Please enter a valid email address.")
        } else {
            setMessage("");
        }
    };


    const handleOnChange = (e) => {
        setEmail(e.target.value);
        const regEx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (regEx.test(email)) {
            setMessage("email address is valid")
        } else if (!regEx.test(email) && email !== '') {
            setMessage("Please enter a valid email address.")
        } else {
            setMessage("");
        }
    };


    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-md sm:text-center">
                    <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl dark:text-white">Sign up for our newsletter</h2>
                    <p className="mx-auto mb-2 max-w-2xl font-light text-gray-500 md:mb-2 sm:text-xl dark:text-gray-400">Stay up to date with the roadmap progress, announcements and exclusive discounts feel free to sign up with your email.</p>
                    <form ref={form} onSubmit={sendEmail}>
                        <div class="items-center mx-auto mb-3 space-y-4 max-w-screen-sm sm:flex sm:space-y-0">
                            <div class="relative w-full">
                                <input type="text" name="email" placeholder="Enter your email" value={email} onChange={handleOnChange} style={{ width: '67%' }} className="text-base leading-6 text-gray-900 placeholder-gray border border-gray-300 rounded-md" />
                                <button disabled={!email} type="submit" value="Send" className="inline-flex items-center text-white bg-[#f2ca50] focus:ring-4 focus:ring-[#d9981e] font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 m-2">Subcribe</button>
                                <br />{message}


                            </div>
                        </div>
                        <div className="mx-auto max-w-screen-sm text-sm text-center text-gray-500 newsletter-form-footer dark:text-gray-300">We care about the protection of your data. <a href="/privacy" className="font-medium text-primary-000 dark:text-primary-000 hover:underline">Read our Privacy Policy</a>.</div>
                    </form>
                </div>
            </div >
        </section >
    )
}
export default Newsletter 