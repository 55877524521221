import React from 'react';
import { Link } from 'react-router-dom';

const Blogs = ({ blogs, attributes }) => {





    return (
        <section class="bg-white dark:bg-gray-900">
            <div className='w-full bg-[#ffffff] py-[50px]'>
                <div className='max-w-[1240px] mx-auto'>
                    <h2 className='font-bold text-4xl my-1 text-center'>More Blog Posts</h2><br />
                    <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 ss:grid-cols-1 gap-8 px-4 text-black'>


                        {blogs.data.map((blog) =>
                            <Link key={blog.id} to={`/blog/${blog.attributes.TitleUrl}`}
                                onClick={() => {
                                    window.scroll({
                                        top: 0,
                                        left: 0,
                                        behavior: "smooth",
                                    });
                                }}>
                                <div className='bg-white  overflow-hidden drop-shadow-md'>
                                    <img className='h-56 w-full object-cover' src={`${blog.attributes.BlogImage}`} alt="Main Image" />
                                    <div className='p-6 bg-[#000]' style={{ minHeight: '280px' }}>
                                        <span className="text-sm  font-medium text-[#f2ca50]">{blog.attributes.BlogTopic}</span>
                                        <h3 className='font-medium text-2xl my-1 text-white'>{blog.attributes.BlogTitle}</h3>

                                        <p className='text-white text-md'>{blog.attributes.BlogDes}</p>
                                    </div>
                                </div>
                            </Link>

                        )}









                        {/* {blogs1.map((blog)=>

                <Link key={blog.id} to={`/blog/${blog.id}`}>
                    <div  className='bg-white rounded-xl overflow-hidden drop-shadow-md'>
                        <img className='h-56 w-full object-cover' src={blog.coverImg} />
                        <div className='p-8'>
                            <h3 className='font-bold text-2xl my-1'>{blog.title}</h3>
                            <p className='text-gray-600 text-xl'>{blog.desc}</p>
                        </div>
                    </div>
                </Link>
                
                
                
                )} */}





                    </div>

                </div>
            </div>
        </section>
    )
}

export default Blogs