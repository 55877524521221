import React from 'react';
import { Navbar, FooterNew, BlogContent, Billboard } from "../components";


const BlogContentPage = ({ blogs }) => {
    console.log(blogs)

    return (
        <div>
            <Navbar />
            <BlogContent blogs={blogs} />
            <Billboard />
            <FooterNew />
        </div>
    )
}

export default BlogContentPage