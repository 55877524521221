
import React from 'react';



const Cta = () => {
    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">Looking to advertise?</h2>
                    <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg"> Henny Maestro works hand-in-hand with each of our partners to ensure their creative messaging resonates best with our audience. </p>
                    <a href="/contact" className="text-white  bg-[#f2ca50] focus:ring-4 focus:ring-[#d9981e] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none dark:focus:ring-primary-800">Contact Us</a>
                </div>
            </div>
        </section>
    )
}
export default Cta 