import React from 'react';
import { Navbar, FooterNew } from "../components"

const Aboutpage = () => {



    return (
        <div>
            <Navbar /><section class="bg-white dark:bg-gray-900">
                <div className="bg-white dark:bg-gray-900">
                    <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                        <div className="max-w-screen-md mb-8 lg:mb-16">
                            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Looking to advertise?</h2>
                            <p className="mb-4 text-gray-500 sm:text-xl dark:text-gray-400">
                                Henny Maestro works hand-in-hand with each of our partners to ensure their creative messaging resonates best
                                with our audience. From editorial alignments to custom content solutions, to social and email strategy,
                                to live (or, even virtual!) events, our sales and creative teams will help you generate some major good vibes
                                in the wellness space.</p>
                            <p className="mb-4 text-gray-500 sm:text-xl dark:text-gray-400">
                                Visit our advertising page to learn more.
                                Are you a publicist looking for coverage of your product or client?<br />
                                Please contact our editor below. Note that due to the volume of pitches we receive each day, we may not be able to respond to every request.
                                <br /><br /><a href="mailto:henry@henny-maestro.com"><b>Editor: Henry@henny-maestro.com</b></a>
                                <br /><br />Are you a journalist or a writer who wants to contribute to Henny Maestro?
                                You can find out here:

                            </p>
                            <p className="mb-4 text-gray-500 sm:text-xl dark:text-gray-400">Do you have a question regarding your subscription to a Henny Maestro newsletter?<br />
                                Please send an email to <a href="mailto:info@henny-maestro.com"><b>info@henny-maestro.com</b></a>.</p>

                            <p className="mb-4 text-gray-500 sm:text-xl dark:text-gray-400">Are you looking to license or reuse content published by Henny Maestro?<br />
                                Contact our official partner, Apaw Holdings Ltd about available usages, license fees, and award seal artwork at <a href="mailto:h.apaw@henryapaw.com"><b>h.apaw@fillmore-media.com</b></a>. Please note that
                                Apaw Holdings is the only authorized company that can provide <br />Henny Maestro branded materials.”
                            </p>


                        </div>

                    </div>
                </div></section>
            <FooterNew />
        </div>
    )
}

export default Aboutpage