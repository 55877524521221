import React from 'react';
import { Helmet } from "react-helmet";
import { Navbar, FooterNew } from "../components"

const Aboutpage = () => {



    return (
        <div>
            <Helmet>

                <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
            </Helmet>
            <Navbar /><section class="bg-white dark:bg-gray-900">
                <div className="bg-white dark:bg-gray-900">
                    <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                        <div className="max-w-screen-md mb-8 lg:mb-16">
                            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white"> Advertise With Henny Maestro</h2>
                            <p className="mb-4 text-gray-500 sm:text-xl dark:text-gray-400">Henny Maestro works hand-in-hand with each of our partners to ensure their
                                creative messaging resonates best with our audience. From editorial alignments to custom content solutions, to social and email strategy,
                                to live events, our sales and creative teams will help you generate some major good vibes in the wellness space.
                            </p>
                            <p className="mb-4 text-gray-500 sm:text-xl dark:text-gray-400">Are you interested in advertising opportunities?
                            </p>
                            <div class="calendly-inline-widget" data-url="https://calendly.com/henryapaw-tech/henny-maestro?text_color=6c6b6b&primary_color=f4da23" style={{ minWidth: '320px', height: '700px' }}></div>

                            <p className="mb-4 text-gray-500 sm:text-xl dark:text-gray-400">Are you a publicist looking for coverage of your product or client?
                                <br /><br /><a href="/contact" className="text-white bg-[#f2ca50] focus:ring-4 focus:ring-[#d9981e] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">Contact Us</a>
                            </p>
                        </div>

                    </div>
                </div></section>
            <FooterNew />
        </div>
    )
}

export default Aboutpage