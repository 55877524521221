import React from 'react';
import { Navbar, FooterNew, Blogs, Billboard, CarouselSlide } from "../components";


const BlogPage = ({ blogs }) => {
    console.log(blogs)

    return (
        <div>
            <Navbar />
            <CarouselSlide />
            <Blogs blogs={blogs} />
            <Billboard />
            <FooterNew />
        </div>
    )
}

export default BlogPage