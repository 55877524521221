
import React from 'react';



const MainCta = () => {
    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                <img className="w-full dark:hidden max-w-[500px]" src="https://pulsio.co.uk/cdn/shop/files/ARB_PULSIO-42_2_1.webp" alt="Fitbit Luxe" />
                <img className="w-full hidden dark:block" src="https://pulsio.co.uk/cdn/shop/files/ARB_PULSIO-42_2_1.webp" alt="Fitbit Luxe" />
                <div className="mt-4 md:mt-0">
                    <p>Sponsored Product</p>
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Pulsio AIR</h2>
                    <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">Unlike any other massage gun, the Pulsio AIR is 50% more powerful, offers a 2-month battery life, an aircraft grade aluminum body, and is in a package no bigger than your phone. </p>
                    <a href="https://www.jdoqocy.com/click-101008892-15610847" target="blank" className="inline-flex items-center text-white bg-[#f2ca50] focus:ring-4 focus:ring-[#d9981e] font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900">
                        Read more
                        <img src="https://www.awltovhc.com/image-101008892-15610847" width="1" height="1" border="0" />
                    </a>
                </div>
            </div>

        </section>
    )
}
export default MainCta 