import React from 'react';
import { Navbar, Blogs, FooterNew, AboutSec, CTA, MainCta, Billboard, Newsletter, FeatBlog } from "../components"

const Homepage = ({ blogs }) => {



    return (
        <div>
            <Navbar />
            <FeatBlog />
            <AboutSec />
            <MainCta />
            <Newsletter />
            <Blogs blogs={blogs} />
            <Billboard />
            <CTA />
            <FooterNew />
        </div>
    )
}

export default Homepage