import React from 'react';
import { mpu } from '../assets';
import { Carousel } from 'flowbite-react';



const Mpu = () => {
    const car = { height: '320px', borderRadius: '0px' }
    return (
        <section className="bg-white dark:bg-gray-900">

            <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <p className="text-center">THIS WEEKS AUDIBLE PICKS</p>
                <Carousel
                    style={car}>
                    <div className="flex h-full items-center justify-center dark:text-white">
                        <a href="https://www.amazon.co.uk/Body-Keeps-Score-Transformation-Trauma/dp/B07XYFYJDM?isALC=true&linkCode=li3&tag=hennymaestr03-21&linkId=c04d3aa0f9e27f7bc21e18bae208c9d0&language=en_GB&ref_=as_li_ss_il" target="_blank"><img border="0" src="https://m.media-amazon.com/images/I/41nC1z83dlL.jpg" width="300" height="300" /></a>
                        <img src="https://ir-uk.amazon-adsystem.com/e/ir?t=hennymaestr03-21&language=en_GB&l=li3&o=2&a=B07XYFYJDM" width="1" height="1" border="0" alt="" />
                    </div>
                    <div className="flex h-full items-center justify-center  dark:text-white">
                        <a href="https://www.amazon.co.uk/Meditations-Breaking-Habit-Being-Yourself/dp/B09P2WQKRS?isALC=true&linkCode=li3&tag=hennymaestr03-21&linkId=5d2c6a8e011e087a1b78d9f3fd0282d5&language=en_GB&ref_=as_li_ss_il" target="_blank"><img border="0" src="https://m.media-amazon.com/images/I/51emI3pY4kL.jpg" width="300" height="300" /></a>
                        <img src="https://ir-uk.amazon-adsystem.com/e/ir?t=hennymaestr03-21&language=en_GB&l=li3&o=2&a=B09P2WQKRS" width="1" height="1" border="0" alt="" />
                    </div>
                    <div className="flex h-full items-center justify-center  dark:text-white">
                        <a href="https://www.amazon.co.uk/Ikigai-Japanese-Secret-Long-Happy/dp/B0759VH4Q9?isALC=true&linkCode=li3&tag=hennymaestr03-21&linkId=ae5980a7be38ecdabb6e084f704a48be&language=en_GB&ref_=as_li_ss_il" target="_blank"><img border="0" src="https://m.media-amazon.com/images/I/511HccWipML.jpg" width="300" height="300" /></a>
                        <img src="https://ir-uk.amazon-adsystem.com/e/ir?t=hennymaestr03-21&language=en_GB&l=li3&o=2&a=B0759VH4Q9" width="1" height="1" border="0" alt="" />
                    </div>
                    <div className="flex h-full items-center justify-center  dark:text-white">
                        <a href="https://www.amazon.co.uk/Breath-New-Science-Lost-Art/dp/B0874XZR9J?isALC=true&linkCode=li3&tag=hennymaestr03-21&linkId=992ef9c32db2f9a23c083ce73817bcab&language=en_GB&ref_=as_li_ss_il" target="_blank"><img border="0" src="https://m.media-amazon.com/images/I/51pwDjKxPnS.jpg" width="300" height="300" /></a>
                        <img src="https://ir-uk.amazon-adsystem.com/e/ir?t=hennymaestr03-21&language=en_GB&l=li3&o=2&a=B0874XZR9J" width="1" height="1" border="0" alt="" />
                    </div>

                </Carousel>

            </div>
        </section>
    )
}
export default Mpu 