import React from 'react';
import { Footer } from 'flowbite-react';
import { BsFacebook, BsInstagram, BsTwitter } from 'react-icons/bs';
import { logo } from '../assets';


const FooterNew = () => {
    return (

        <Footer container className="bg-[#000]" style={{ borderRadius: '0' }}>
            <div className="w-full">
                <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
                    <div className='foot-logo'>
                        <Footer.Brand
                            alt="Henny Maestro Logo"
                            href="/"
                            name=""
                            src={logo}
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
                        <div>
                            <Footer.Title title="Brand" />
                            <Footer.LinkGroup col>
                                <Footer.Link href="/about">
                                    About Us
                                </Footer.Link>
                                <Footer.Link href="/contact">
                                    Contact Us
                                </Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                        <div>
                            <Footer.Title title="Solutions" />
                            <Footer.LinkGroup col>
                                <Footer.Link href="/advertise">
                                    Advertise
                                </Footer.Link>
                                <Footer.Link href="http://www.henryapaw.com/calculator">
                                    Free Reading
                                </Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                        <div>
                            <Footer.Title title="Legal" />
                            <Footer.LinkGroup col>
                                <Footer.Link href="/privacy">
                                    Privacy Policy
                                </Footer.Link>
                                <Footer.Link href="/terms">
                                    Terms & Conditions
                                </Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                    </div>
                </div>
                <Footer.Divider style={{ borderColor: '#6b7280' }} />
                <div className="w-full sm:flex sm:items-center sm:justify-between">
                    <Footer.Copyright
                        by="Henny Maestro™ All rights reserved to APAW HOLDINGS Limited."
                        href="#"
                        year={2023}
                    />
                    <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
                        <Footer.Icon
                            href="https://www.facebook.com/HennyMaestroLDN/"
                            icon={BsFacebook}
                        />
                        <Footer.Icon
                            href="https://www.instagram.com/hennymaestro/"
                            icon={BsInstagram}
                        />
                        <Footer.Icon
                            href="https://twitter.com/HennyMaestroLDN"
                            icon={BsTwitter}
                        />


                    </div>
                </div>
            </div>
        </Footer>
    )
}

export default FooterNew