import React from 'react';
import { billboard } from '../assets';
import { Carousel } from 'flowbite-react';



const Billboard = () => {
    const car = { height: '250px', borderRadius: '0px' }
    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 items-center justify-center">
                <p className="text-center">This weeks Kindle picks</p>
                <Carousel
                    style={car}>
                    <div className="flex h-full items-center justify-center dark:text-white">
                        <a href="https://amzn.to/48rCJOO" target="_blank"><img border="0" width="970px" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1706052363/hm-blog/billboard/billboard-kindle_adrayb.jpg" /></a>

                    </div>
                    <div className="flex h-full items-center justify-center  dark:text-white">
                        <a href="https://amzn.to/3thKXsW" target="_blank"><img border="0" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1706052360/hm-blog/billboard/billboard-eat_fttcyc.jpg" /></a>

                    </div>
                    <div className="flex h-full items-center justify-center  dark:text-white">
                        <a href="https://amzn.to/46wiAW8" target="_blank"><img border="0" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1706052359/hm-blog/billboard/billboard-medi_crfmab.jpg" /></a>

                    </div>
                    <div className="flex h-full items-center justify-center  dark:text-white">
                        <a href="https://amzn.to/3ZDM1Dm" target="_blank"><img border="0" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1706052363/hm-blog/billboard/billboard-manly_ycfis1.jpg" /></a>

                    </div>

                </Carousel>
            </div>
        </section>
    )
}
export default Billboard 