import React from 'react';
import { Navbar, FooterNew } from "../components"

const Aboutpage = () => {



    return (
        <div>
            <Navbar />
            <section class="bg-white dark:bg-gray-900">
                <div className="bg-white dark:bg-gray-900">
                    <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                        <div className="max-w-screen-md mb-8 lg:mb-16">
                            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">About Us</h2>
                            <h3 className="mb-2 text-xl font-bold dark:text-white">Who are we?</h3>
                            <p className="mb-4 text-gray-500 sm:text-xl dark:text-gray-400">
                                Henny Maestro is a lifestyle brand for that modern consumer who is a first or second generation descendent of Africa, or anyone who has affinity to Africa and its many cultures.</p>
                            <h3 className="mb-2 text-xl font-bold dark:text-white">Mission?</h3>
                            <p className="mb-4 text-gray-500 sm:text-xl dark:text-gray-400">
                                We aim to create great digital content (blogs, music, video and software) plus high-end products.<br />
                                I.E. Fine Jewelry, Apparel, knitwear, and Leather goods.</p>
                            <h3 className="mb-2 text-xl font-bold dark:text-white">Vision?</h3>
                            <p className="mb-4 text-gray-500 sm:text-xl dark:text-gray-400">
                                Henny Maestro vision is: To become one of the top major brands in the country. Empowering and advancing our message of Positivity, Unity, and Love.
                            </p>
                        </div>

                    </div>
                </div>
            </section>
            <FooterNew />
        </div>
    )
}

export default Aboutpage