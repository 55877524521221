
import React from 'react';
import { Carousel } from 'flowbite-react';
import { Link } from 'react-router-dom';


const CarouselSlide = () => {
    const car = { height: '400px', borderRadius: '0px' }
    return (
        <div >
            <Carousel
                style={car}>

                <img
                    alt="..."
                    src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1706049322/blog_cover_3_45ca4ea679_dwyzvf.jpg"
                />

                <img
                    alt="..."
                    src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1706049319/blog_cover_8_7b11ca68ac_csjvza.jpg"
                />
                <img
                    alt="..."
                    src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1706049310/blog_cover_2_ba3fed1ebf_mufj1n.jpg"
                />
                <img
                    alt="..."
                    src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1706049313/blog_cover_6_b9c1a31662_cmlqel.jpg"
                />

            </Carousel>
        </div>
    )
}
export default CarouselSlide 